import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/coqui-ai.github.io/coqui-ai.github.io/src/templates/NewsletterTemplate.tsx";
import { graphql } from 'gatsby';
export const pageQuery = graphql`
  query($fileAbsolutePath: String) {
    ...SidebarPageFragment
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "934px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "27.599999999999998%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAA3XAAAN1wFCKJt4AAABCElEQVQY042RQUsCcRDF/VB9g+59gk6eOhTRpaBbh5CgYCuiTkYGah6CSIwiyiRbiiyVFg+5EoS6LO1/XS0oXffXriC1atCDOcyDeW/mTQAXjuMwhFHcH/g9H/CaPqG/W5T0V1ShUTbqjDIb7L/abR/vE8y8KEjyMfv5NHuFS87kO0LhCBvxBOuxBPHTc0K7EVJZGV2YhI+SZAvF3qzd7f4I9h287WpNgfho8WzWWdjaZnFzh+DSMtMra0zMzTO7KjE2GewJj0/NcOKKe+jY9vCGWsvkvlom55ZqamhvBqnrG26fFDIPeYTVRIoecHiR5ir3iKJWMBqW/+TBbDynz46by/9/4sv1G2shujMHTg19AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/c9103bc33c8add5ab1fa4fa1c49c90ef/ca463/logo-wordmark.png",
          "srcSet": ["/static/c9103bc33c8add5ab1fa4fa1c49c90ef/43fa5/logo-wordmark.png 250w", "/static/c9103bc33c8add5ab1fa4fa1c49c90ef/c6e3d/logo-wordmark.png 500w", "/static/c9103bc33c8add5ab1fa4fa1c49c90ef/ca463/logo-wordmark.png 934w"],
          "sizes": "(max-width: 934px) 100vw, 934px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "work-at-coqui",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#work-at-coqui",
        "aria-label": "work at coqui permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`👩‍💻Work at Coqui`}</h3>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/kdavis-coqui"
      }}>{`Kelly Davis`}</a></p>
    <p>{`Yeah, you heard that right; we’re hiring!`}</p>
    <p>{`An open source remote-friendly Berlin based startup founded by the creators of Mozilla’s
`}<a parentName="p" {...{
        "href": "https://github.com/mozilla/tts"
      }}>{`text-to-speech`}</a>{` (TTS) and `}<a parentName="p" {...{
        "href": "https://github.com/mozilla/deepspeech"
      }}>{`speech-to-text`}</a>{`
(STT) engines (over `}<a parentName="p" {...{
        "href": "https://somsubhra.github.io/github-release-stats/?username=mozilla&repository=deepspeech&page=1&per_page=300"
      }}>{`600K downloads`}</a>{`
and 23K GitHub stars), with the backing of investors from around the globe (London,
San Francisco, and Berlin), `}<em parentName="p">{`and`}</em>{` we’re hiring!`}</p>
    <p>{`What’s not to love?`}</p>
    <p>{`We’re hiring across-the-board for a number of roles; so, there’s something for everyone:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/job/head-of-product"
        }}>{`Head of Product`}</a></li>
      <li parentName="ul">{`3 x `}<a parentName="li" {...{
          "href": "/job/senior-full-stack-engineer"
        }}>{`Senior Full Stack Engineers`}</a></li>
      <li parentName="ul">{`2 x `}<a parentName="li" {...{
          "href": "/job/senior-stt-deep-learning-engineer"
        }}>{`Senior STT Deep Learning Engineers`}</a></li>
      <li parentName="ul">{`2 x `}<a parentName="li" {...{
          "href": "/job/senior-tts-deep-learning-engineer"
        }}>{`Senior TTS Deep Learning Engineers`}</a></li>
      <li parentName="ul">{`2 x `}<a parentName="li" {...{
          "href": "/job/senior-developer-community-manager"
        }}>{`Senior, Developer Community Managers`}</a></li>
    </ul>
    <p>{`The full list of open positions is available on our `}<a parentName="p" {...{
        "href": "/jobs"
      }}>{`jobs page`}</a>{`.`}</p>
    <p>{`We’d love to hear from you; so, if any roles pique your interest, reach out to
`}<a parentName="p" {...{
        "href": "mailto:jobs@coqui.ai"
      }}>{`jobs@coqui.ai`}</a>{`. 🐸!`}</p>
    <h3 {...{
      "id": "introduction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#introduction",
        "aria-label": "introduction permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`👋🏽Introduction`}</h3>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/kdavis-coqui"
      }}>{`Kelly Davis`}</a></p>
    <p>{`Once again we’re cooking up some speech goodness for you!`}</p>
    <p>{`Delivered fresh, we’ve `}<a parentName="p" {...{
        "href": "https://github.com/coqui-ai/TTS/releases"
      }}>{`v0.2.1`}</a>{`, a new
`}<a parentName="p" {...{
        "href": "https://github.com/coqui-ai/TTS"
      }}>{`🐸TTS`}</a>{` version for you to dig into. With a new end-to-end
`}<a parentName="p" {...{
        "href": "https://github.com/coqui-ai/TTS"
      }}>{`🐸TTS`}</a>{` model and 5 new pre-trained models, one
can synthesize voices from `}<strong parentName="p">{`110 different speakers`}</strong>{`. It’s chock full of goodness.`}</p>
    <p>{`Coqui on the inside! Beyond the open source goodness we’re delivering, Coqui’s quietly being
integrated into evermore products. For example, `}<a parentName="p" {...{
        "href": "https://www.galacticbioware.com/"
      }}>{`Galactic Bioware`}</a>{`,
a protective and smart-wear company, is integrating `}<a parentName="p" {...{
        "href": "https://github.com/coqui-ai/STT"
      }}>{`🐸STT`}</a>{`
into their smart firefighter suits. The applications are endless. Also,
`}<a parentName="p" {...{
        "href": "https://translatorswithoutborders.org/"
      }}>{`Translators without Borders/CLEAR Global`}</a>{` is using
Swahili `}<a parentName="p" {...{
        "href": "https://github.com/coqui-ai/STT"
      }}>{`🐸STT`}</a>{` for chatbots, transcriptions, and telephone
assistance. Nice!`}</p>
    <p>{`Where there’s a speech conference there’s a Coqui. We just presented
`}<a parentName="p" {...{
        "href": "https://www.isca-speech.org/archive/interspeech_2021/casanova21b_interspeech.html"
      }}>{`our latest TTS work`}</a>{`
at Interspeech, `}<em parentName="p">{`the`}</em>{` speech conference of the year! Also, the `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=3_U-9p1gzQE"
      }}>{`video`}</a>{`
of our presentation at Rasa’s L3-AI was just released.`}</p>
    <p>{`Enjoy the newsletter!`}</p>
    <h3 {...{
      "id": "v021-new-tts-version-delivered-fresh",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#v021-new-tts-version-delivered-fresh",
        "aria-label": "v021 new tts version delivered fresh permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`v0.2.1, New 🐸TTS Version Delivered Fresh`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "72%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAAOABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQGAQP/xAAWAQEBAQAAAAAAAAAAAAAAAAAGBAX/2gAMAwEAAhADEAAAAX1ucmRTVxhLqf/EABkQAAMBAQEAAAAAAAAAAAAAAAMEBQIBIv/aAAgBAQABBQLPOMGVXC1o/glXpVk5Vgm7z9Pax//EABwRAAICAgMAAAAAAAAAAAAAAAECACESMSJR8P/aAAgBAwEBPwFODqrDHdDXqhJ7n//EAB4RAQABAwUBAAAAAAAAAAAAAAECAAMREiFBYbHh/9oACAECAQE/AWI2pysuQxvz37RqT61//8QAIhAAAgEEAgEFAAAAAAAAAAAAAQIDABESIQQxIiNBUWJx/9oACAEBAAY/ArxZABdtPpVH52aCxWSaLd3Hi9/kURLxuRl9DkKl5gl3Enpi3RrBjksyBDlWAQde1f/EAB4QAQEAAgICAwAAAAAAAAAAAAERACExUWFxkaHB/9oACAEBAAE/IdgmzaraPZ9GQJACHkHZe8kR+FPwn7vESWgGoyvq4dQxwLZb85JjBy1n/9oADAMBAAIAAwAAABC4/wD/xAAaEQEAAgMBAAAAAAAAAAAAAAABETEAUWGB/9oACAEDAQE/EGhqpBZIXvG3wTDn/8QAGxEBAAICAwAAAAAAAAAAAAAAAREhAIExcfD/2gAIAQIBAT8Qt8xKbAwvkjpW1ETxtz//xAAdEAEBAAMBAAMBAAAAAAAAAAABEQAhMUFhcYGR/9oACAEBAAE/EOPe1WRKjODEa3JPcYFeVAEOhBKMQQrykpBca06APcRkiFCLNGH5b9YR6aKQA4EP8+cWkFIu2xdl3L+5/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/1e77dc6fce06f5a577ab0e4d0674e779/dbdff/release.jpg",
          "srcSet": ["/static/1e77dc6fce06f5a577ab0e4d0674e779/0988f/release.jpg 250w", "/static/1e77dc6fce06f5a577ab0e4d0674e779/d1f95/release.jpg 500w", "/static/1e77dc6fce06f5a577ab0e4d0674e779/dbdff/release.jpg 1000w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/erogol"
      }}>{`Eren Gölge`}</a></p>
    <p>{`This new version introduces a new end-to-end TTS model implementation and 5 pre-trained models.
Check the `}<a parentName="p" {...{
        "href": "https://github.com/coqui-ai/TTS/releases"
      }}>{`release notes`}</a>{` for all the details of the
release and `}<a parentName="p" {...{
        "href": "https://github.com/coqui-ai/TTS/issues/378"
      }}>{`dev plans`}</a>{` to see what is next.`}</p>
    <p>{`We added the VITS model to the 🐸TTS model collection. It is the first end-to-end TTS model
implementation added to our library. It directly converts the input text to the waveform without
needing an external vocoder training. For more info about VITS, please see the
`}<a parentName="p" {...{
        "href": "https://arxiv.org/abs/2106.06103"
      }}>{`paper`}</a>{` and the
`}<a parentName="p" {...{
        "href": "https://tts.readthedocs.io/en/latest/models/vits.html"
      }}>{`documentation`}</a>{`.`}</p>
    <p>{`We are also releasing the following pre-trained models:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Tacotron2 with Double Decoder Consistency (DDC) trained on the LJSpeech dataset, using phonemes
as the input. Try it out:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`tts --model_name tts_models/en/ljspeech/tacotronDDC_ph --text "hello, how are you today?"
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`A UnivNet vocoder to work with the model above.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`HifiGAN vocoder trained on Japanese Kokoro dataset (👑`}<a parentName="p" {...{
            "href": "https://github.com/kaiidams"
          }}>{`@kaiidams`}</a>{`)
to complement the Japanese Tacotron2 DDC. Try it out:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`tts --model_name tts_models/ja/kokoro/tacotron2-DDC --text "こんにちは、今日はいい天気ですか？"
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`VITS trained on the English LJSpeech dataset. Try it out:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`tts --model_name tts_models/en/ljspeech/vits --text "hello, how are you today?"
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`VITS multi-speaker model trained on the English VCTK dataset. This model can synthesize voices
from `}<strong parentName="p">{`110 different speakers`}</strong>{`. Try it out:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`tts-server --model_name tts_models/en/vctk/vits
`}</code></pre>
      </li>
    </ul>
    <p>{`Huge thanks to:`}</p>
    <ul>
      <li parentName="ul">{`👑 Agrin Hilmkil `}<a parentName="li" {...{
          "href": "https://github.com/agrinh"
        }}>{`@agrinh`}</a></li>
      <li parentName="ul">{`👑 Ayush Chaurasia `}<a parentName="li" {...{
          "href": "https://github.com/AyushExel"
        }}>{`@AyushExel`}</a></li>
      <li parentName="ul">{`👑 `}<a parentName="li" {...{
          "href": "https://github.com/fijipants"
        }}>{`@fijipants`}</a></li>
    </ul>
    <p>{`for their contributions to these versions.`}</p>
    <h3 {...{
      "id": "coqui-on-the-inside-super-suits-for-superheroes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#coqui-on-the-inside-super-suits-for-superheroes",
        "aria-label": "coqui on the inside super suits for superheroes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Coqui on the Inside: Super Suits for Superheroes👩🏻‍🚒`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.8%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAYEBQf/xAAXAQADAQAAAAAAAAAAAAAAAAACBAUG/9oADAMBAAIQAxAAAAFjqM5dsxTUSINF/8QAHBAAAQQDAQAAAAAAAAAAAAAABAIDBQYAARMU/9oACAEBAAEFApawPdD5VoVr3qsO3ZtRBtmBVrIdnmP/AP/EABkRAQADAQEAAAAAAAAAAAAAAAEAAhEDIv/aAAgBAwEBPwE64eZW1k1Z/8QAHhEAAgIABwAAAAAAAAAAAAAAAQIAAwQhIkFRYfD/2gAIAQIBAT8BuwlblRfv37mFVq0IMhP/xAAoEAACAQMBBQkAAAAAAAAAAAABAgMAERIUBBMhIjEjMkFCUWGx4fD/2gAIAQEABj8C7Q6ZN9zRcG5L2+6E+kVwj5uMOq3OPxTbS7mCxwCrbp+NRzSx5SSlDfLun2qSUzsQkdyng1zTi/n9K//EABwQAAICAwEBAAAAAAAAAAAAAAERACExQVFxgf/aAAgBAQABPyFaihcYirjQQRiqODWIT4KepaiT5SwysxqG+yChhjo3ChiQQVNfFEo9ifgT/9oADAMBAAIAAwAAABCf3//EABoRAQACAwEAAAAAAAAAAAAAAAEAEUFxwfD/2gAIAQMBAT8QdsCueYXRe5//xAAZEQEBAQEBAQAAAAAAAAAAAAABESEAQTH/2gAIAQIBAT8Quxa9Stoe/cOTEk5acyCGHf/EABkQAQEBAQEBAAAAAAAAAAAAAAERITEAQf/aAAgBAQABPxBDJgAQiSbFDAsO1nuR4QU7s1QWFm+lqqchrdOmgcM8ehYHRIVI36ArkT5M+cxgdHk+AAHkNkuQVnhcM57/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/8f2459aaa681b6ca5cc1171221b49cf1/dbdff/super_suits_for_superheroes.jpg",
          "srcSet": ["/static/8f2459aaa681b6ca5cc1171221b49cf1/0988f/super_suits_for_superheroes.jpg 250w", "/static/8f2459aaa681b6ca5cc1171221b49cf1/d1f95/super_suits_for_superheroes.jpg 500w", "/static/8f2459aaa681b6ca5cc1171221b49cf1/dbdff/super_suits_for_superheroes.jpg 1000w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/JRMeyer"
      }}>{`Josh Meyer`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.galacticbioware.com/"
      }}>{`Galactic Bioware`}</a>{`, a protective and smart-wear company
based in Australia and the US, is integrating `}<a parentName="p" {...{
        "href": "https://github.com/coqui-ai/STT"
      }}>{`🐸STT`}</a>{`
into their future lines of smart firefighter suits. Firefighters wear heavy suits and gloves
in order to protect themselves, but rapid communication is critical. Handheld walkie-talkies
are not ideal, but they’re still widely used. `}<a parentName="p" {...{
        "href": "https://github.com/coqui-ai/STT"
      }}>{`🐸STT`}</a>{` is
small enough to live inside the suit itself, which is critical in high-stakes situations where
any delay in communication could cost lives. The firefighter will be able not only to
communicate with their team and back to base, but use voice commands to access information
from the suit’s sensors, such as surrounding temperature and oxygen levels.`}</p>
    <h3 {...{
      "id": "coqui-on-the-inside-twb--coqui--swahili--3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#coqui-on-the-inside-twb--coqui--swahili--3",
        "aria-label": "coqui on the inside twb  coqui  swahili  3 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Coqui on the Inside: TWB + Coqui + Swahili = <3`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "26.8%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/6e86e7167e88292ae0d167d54b53efff/da8b6/translators_without_borders.png",
          "srcSet": ["/static/6e86e7167e88292ae0d167d54b53efff/43fa5/translators_without_borders.png 250w", "/static/6e86e7167e88292ae0d167d54b53efff/c6e3d/translators_without_borders.png 500w", "/static/6e86e7167e88292ae0d167d54b53efff/da8b6/translators_without_borders.png 1000w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/JRMeyer"
      }}>{`Josh Meyer`}</a></p>
    <p>{`We previously told you about progress being made at
`}<a parentName="p" {...{
        "href": "https://translatorswithoutborders.org/"
      }}>{`Translators without Borders/CLEAR Global`}</a>{` (TWB)
for `}<a parentName="p" {...{
        "href": "https://github.com/coqui-ai/STT"
      }}>{`🐸STT`}</a>{` and the Bengali language, and we already
have more news!`}</p>
    <p>{`TWB trained a production-worthy Swahili speech-to-text model (for Congolese Swahili)
using `}<a parentName="p" {...{
        "href": "https://github.com/coqui-ai/STT"
      }}>{`🐸STT`}</a>{`, and they generously released the model
in `}<a parentName="p" {...{
        "href": "/swahili-congo/twb/v0.3.0"
      }}>{`the Coqui Model Zoo`}</a>{`. This Swahili model
will help develop use-cases like voice chatbots, machine-assisted transcription, and
telephone IVR to empower humanitarian communication in the Democratic Republic of Congo.`}</p>
    <p><a parentName="p" {...{
        "href": "https://alpoktem.github.io/"
      }}>{`Alp Öktem`}</a>{`, computational linguist at TWB and co-founder
of `}<a parentName="p" {...{
        "href": "https://collectivat.cat/en"
      }}>{`Col·lectivaT`}</a>{`, trained the model using only 12 hours of
data, which are also made publicly available from the
`}<a parentName="p" {...{
        "href": "http://gamayun.translatorswb.org/data/"
      }}>{`TWB project portal`}</a>{`. Keep an eye out for new
models and new voice-enabled applications from TWB!`}</p>
    <h3 {...{
      "id": "interspeech-the-speech-conference-and-were-there",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#interspeech-the-speech-conference-and-were-there",
        "aria-label": "interspeech the speech conference and were there permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Interspeech, `}<em parentName="h3">{`the`}</em>{` Speech Conference, and We’re There📣`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "70.4%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAAOABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABgACBP/EABUBAQEAAAAAAAAAAAAAAAAAAAIE/9oADAMBAAIQAxAAAAEXyBd3QLotMf/EABsQAAEFAQEAAAAAAAAAAAAAAAEAAgMEFQUS/9oACAEBAAEFAhzavmbnMWeULT2nRnC1bC//xAAeEQABAwQDAAAAAAAAAAAAAAADAAEEERIh4SJxof/aAAgBAwEBPwFo5LuRMdbTxzVwTza//8QAHREAAAYDAQAAAAAAAAAAAAAAAAECBBGhAyEi4f/aAAgBAgEBPwE3ZRyiwTxEbx34P//EACAQAAEDAgcAAAAAAAAAAAAAAAEAAhEhIxITIjEyM3H/2gAIAQEABj8CbOcXRWAreM+hcSgaJtw6dl2uX//EAB4QAAICAgIDAAAAAAAAAAAAAAERACExUUFhgZHB/9oACAEBAAE/IRWDWAAR9bgOJ3i4Cd54gMGjVL7AWoC1DycmNVS1P//aAAwDAQACAAMAAAAQcB//xAAaEQEBAAIDAAAAAAAAAAAAAAARAQBBIZGx/9oACAEDAQE/EDqm0hva8xojTzx//8QAGhEAAQUBAAAAAAAAAAAAAAAAAQARITFBkf/aAAgBAgEBPxB3EuEw4yFC7Uf/xAAaEAEBAQEBAQEAAAAAAAAAAAABESEAMUFh/9oACAEBAAE/EC4GjKBZaTW8qCgUlGCz5fvPzA+Io8thEBQp7acxn51pNSXAgDgpjPeRAEJFDv/Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/e45e229f0064969d10e5fb61376595c9/dbdff/interspeech2021.jpg",
          "srcSet": ["/static/e45e229f0064969d10e5fb61376595c9/0988f/interspeech2021.jpg 250w", "/static/e45e229f0064969d10e5fb61376595c9/d1f95/interspeech2021.jpg 500w", "/static/e45e229f0064969d10e5fb61376595c9/dbdff/interspeech2021.jpg 1000w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/erogol"
      }}>{`Eren Gölge`}</a></p>
    <p>{`This week 👑 `}<a parentName="p" {...{
        "href": "https://github.com/Edresson"
      }}>{`Edresson Casanova`}</a>{` presented one of our
latest works, the SC-Glow multi-speaker TTS, at Interspeech 2021. You can see
the paper `}<a parentName="p" {...{
        "href": "https://www.isca-speech.org/archive/interspeech_2021/casanova21b_interspeech.html"
      }}>{`here`}</a>{`
and checkout the poster below. You can already try out the model with 🐸TTS!`}</p>
    <p>{`Big shout out to all the 🐸 community for making this work possible!! Here’s our poster:`}</p>
    <p><a parentName="p" {...{
        "href": "https://coqui-ai.s3.us-west-2.amazonaws.com/website/interspeech2021_poster.pdf"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1000px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.400000000000006%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACRElEQVQoz2VT21ITQRDdr/Rb/Alf9QHUQqSSgBEkxTXIraAoXywESgVDIJdN9jq7O7uz9+TY0xS+OFVd2zPdc6bP6V5jrgSUsCECAWCG2ayClCGE8BEEPp37sOwpbNuivaBzD57vIk4k5c+RzWv0PQdTOtN7o7v7Fc2Pa3i/sILm8mfy19H40MbSYoNt4fUSTo/PsdXZxVpzHe3VL+Tvod3qoEX5K5T/7m0Li2+WMR6ZMEzTxOPjgCqw6WCM6WQK13XhOk/m2A7SNOVYr9eDOTZhWVStCCAjCZUkSJWCIqvrCoaUEdOLohARUQ2jgP0sT1EUOcqqgF6e5zGQEAJxLFFVJf5fRPnw4JSobGCjvYXN9W2yHWx39vGp2UG7sYlVonRz9Yv0CzCZWARqw/d91lM/rIG16eoY0Pc9DIdDOI6DMCQaUvLlgKr2RIiHgcXJOjadTggkolyb967rYDQaYUKyaXAG1OVrypp6nqWoZzOkkYfK6eHkx0+8ePkKIoyYUKJiilcMmFFumiryLQK2/0ljaDDTHHNSHEf0BqAiemByD9d3cHx5hpQuDwdD3N3+Qf/+AddXN+zf/r7D+ekFLs6+4fL7NRJqkKG16Pfvec50E/RSaUbBpwqUClBkGY/OEel9eHDyZN0T3nd3jtj2tw/huR4MrYUG05Q1jaIokOc5dTuETBTCQGJe19Baaw31Vw83D34omJWM9V2FOQ25UZYFV6ItzzMGq6oEluhDSNKyrChxxtXr+HNHn427XJb0h9XclL8jMihns1M7+AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "IMAGE",
            "title": "IMAGE",
            "src": "/static/0eaf80de8d0b6ade93aa691a6c4dbdda/da8b6/interspeech2021_small_poster.png",
            "srcSet": ["/static/0eaf80de8d0b6ade93aa691a6c4dbdda/43fa5/interspeech2021_small_poster.png 250w", "/static/0eaf80de8d0b6ade93aa691a6c4dbdda/c6e3d/interspeech2021_small_poster.png 500w", "/static/0eaf80de8d0b6ade93aa691a6c4dbdda/da8b6/interspeech2021_small_poster.png 1000w"],
            "sizes": "(max-width: 1000px) 100vw, 1000px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span></a></p>
    <h3 {...{
      "id": "onward-and-upward-presentation-at-rasas-l3-ai",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#onward-and-upward-presentation-at-rasas-l3-ai",
        "aria-label": "onward and upward presentation at rasas l3 ai permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Onward and Upward🚀, Presentation at Rasa’s L3-AI`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "50%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAABx0lEQVQoz31STU8aURSdIB/DvDcfIAqIsqggMwyjMjMgrRqjsbZG1MQNYaebduuGuMO4aRpX3bjpz+l/6O85vfdNMFaJi5M7782559yPp1lOgOVKDKccwSIIu4O88GDI9rtIOD4MEYA1SsshTDuAVq31EcVD1Le/YoVgk2jecF8JeHNMPAjZIcEuams78IPPqjAtl3exkGkgm21Cz20QgYimT+T5VelkNkNiktylOZ+ixkQWMAgZEkxnG0hl1lWcJb0U4LZMZxPSCtR5VrkwE1PtpfuGe4h2+wvC7iV8/xTNtTEK9gCLS9vKdGdwicfHX3h4+Imnp9/49n2CrN76r4tnQW49jM4Qhhf49HGEuH8BSw4g5ZYaOAtGvSGm9z8wmUxxdzfF9c2tynsrKNpqYzxLRir9QUVdtObMLeEyWEwXDE/NnNvWVIJoImc05i7hNZ6FDQ856WLVjFGxI6SMFtKZJrRCMcJ6fYxS8RjSTobO74rfo4LFMTlLy6f/HQX+ZgPbpOXoLg6ORuj1z6EVSyEO/T842fyL6so+ytUuVusDOJUINsGi+Tm1HpxqjCItZ6kcEidCYXGLKqUNU5sL9CJ2965IcIh/F/QPtKqJincAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/d76069cc5cdf496292251efa21b118ac/da8b6/l3-ai.png",
          "srcSet": ["/static/d76069cc5cdf496292251efa21b118ac/43fa5/l3-ai.png 250w", "/static/d76069cc5cdf496292251efa21b118ac/c6e3d/l3-ai.png 500w", "/static/d76069cc5cdf496292251efa21b118ac/da8b6/l3-ai.png 1000w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/JRMeyer"
      }}>{`Josh Meyer`}</a></p>
    <p>{`We made an appearance at the L3-AI conference on conversational AI and in case
you missed it, you can `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=3_U-9p1gzQE"
      }}>{`watch the Coqui presentation video`}</a>{`
online! Listen to our co-founder Josh talk about building modern speech pipelines
for the enterprise that scale to new users, new domains, and new
applications.`}</p>
    <p>{`This isn’t the first time we’ve chatted with Rasa. If you missed our earlier podcast, we
talk about the lay of the land for open speech technology, and you can
`}<a parentName="p" {...{
        "href": "https://open.spotify.com/episode/5KjSoCif3liIExKjTWVtxd"
      }}>{`listen to the podcast`}</a>{` today.`}</p>
    {
      /* markdownlint-enable line-length */
    }


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      